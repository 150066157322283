.App {
  text-align: center;
   /* background-color: #282c34;  */
}

html, body, #app, #app>div {
  height: 100%
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}

.App-body {
  /* background-color: #282c34; */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  /* color: white; */
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
   -khtml-user-select: none; 
     -moz-user-select: none; 
      -ms-user-select: none; 
          user-select: none;                             
}

.fit-screen {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.App-components {
  /* background-color: #2a2a2a; */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  /* color: white; */
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.App-link {
  color: #61dafb;
}

section {
  padding-top: 1rem;
}

.particles{
  position:absolute !important;
  }

  a, a:link, a:visited, a:focus, a:hover, a:active{
    color:white;
    text-decoration:none; 
    cursor: pointer;
  }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
